
















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "counts-card" as string,

  props: {
    color: String as PropType<string>,
    title: String as PropType<string>,
    count: Number as PropType<number>
  }
})
