



































































import Vue from "vue";
import "vue-select/dist/vue-select.css";
import filterCircleIcon from "@iconify-icons/ion/filter-circle";
import { mapActions, mapGetters } from "vuex";
import { VenueData } from "@/types";
import DashboardFilterCustomSelect from "@/components/DashboardFilterCustomSelect.vue";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
import router from "@/router";

export default Vue.extend({
  mixins: [DatePicker],
  name: "header-search",

  components: {
    "dashboard-filter-custom-select": DashboardFilterCustomSelect,
  },

  data() {
    return {
      icons: {
        home: filterCircleIcon,
      },
      selectedVenues: [] as Array<number>,
      startAt: "",
      endAt: "",
      lang: DatePicker,
      i18: i18n,
      autoFilter: "",
    };
  },
  async mounted(): Promise<void> {
    await this.fetchFilter();
    await this.setSelectedVenuesIds(this.selectedVenues);
    await this.fetchVenues({ fetchRatings: false, name: "" });
    await this.setFilterParameters();
    if (router.currentRoute.name == "dashboard") {
      this.autoDashboardUpdate();
      this.filterDashboard(false);
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      venues: "GET_VENUES",
    }),
    ...mapGetters("companyModule", {
      company_id: "GET_COMPANY_ID",
    }),
    ...mapGetters("dashboardModule", {
      startAtPeriod: "GET_START_AT",
      endAtPeriod: "GET_END_AT",
      storedVenuesIds: "GET_SELECTED_VENUES_IDS",
      filter: "GET_FILTER_1",
    }),
    disableFilter(): boolean {
      return !this.selectedVenues.length || !this.startAt || !this.endAt;
    },
  },

  methods: {
    ...mapActions("dashboardModule", {
      setDashboard: "FETCH_DASHBOARD",
      setSelectedVenuesIds: "SET_SELECTED_VENUES_IDS",
      setFilter: "SET_FILTER",
      fetchFilter: "FETCH_FILTER",
    }),
    ...mapActions("venueModule", {
      fetchVenues: "FETCH_VENUES",
    }),
    autoDashboardUpdate() {
      if (router.currentRoute.name == "dashboard") {
        clearInterval(this.autoFilter);
        this.autoFilter = setInterval(() => {
          this.filterDashboard(false);
        }, 300000); //update every 5 min
      }
    },
    setVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    async filterDashboard(autoupdate): Promise<void> {
      let uniqueVenuesIdsString: string = "";
      this.selectedVenues.forEach((v: number) => {
        uniqueVenuesIdsString += `venue_ids=${v}&`;
      });

      const payload: object = {
        venue_ids: uniqueVenuesIdsString.slice(0, -1),
        start_at: this.startAt,
        end_at: this.endAt,
        venue_owner_ids: this.company_id,
        filter: true,
      };
      this.setSelectedVenuesIds(this.selectedVenues);
      if (autoupdate) {
        payload["autoupdate"] = autoupdate;
      }
      await this.setDashboard(payload);
    },
    async filterDashboardSubmit() {
      await this.setFilter({
        venue_ids: this.selectedVenues,
        start_at: this.startAt,
        end_at: this.endAt,
      });
      this.filterDashboard(true);
    },
    setFilterParameters(): void {
      this.startAt = this.startAtPeriod;
      this.endAt = this.endAtPeriod;
      this.selectedVenues = this.storedVenuesIds;
    },
    formatDate(date: Date): string {
      return date.toISOString().split('T')[0];
    },
  },

  watch: {
    venues: {
      async handler(value: Array<VenueData>): Promise<void> {
        this.setFilterParameters();
      },
    },
    company_id: {
      async handler() {
        await this.fetchFilter();
        await this.setFilterParameters();
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.autoFilter);
    this.autoFilter = "";
  },
});
