
























import Vue, {PropType} from "vue";
import {QuestionaryQuestionData, VenueData} from "@/types";
import {mapGetters} from "vuex";

export default Vue.extend({
  name: "dashboard-filter-custom-select" as string,

  props: {
    data: Array as PropType<Array<QuestionaryQuestionData> | Array<VenueData>>,
    canSelectAll: Boolean as PropType<boolean>,
    selectedData: Array as PropType<Array<number> | Array<any>>
  },

  data() {
    return {
      allSelected: false,
      selectedValues: !!this.selectedData ? this.selectedData : []
    }
  },
 mounted() {
   this.selectedValues = this.storedVenuesIds
  },

  computed: {
    ...mapGetters("dashboardModule", {
      storedVenuesIds: "GET_SELECTED_VENUES_IDS"
    }),
    dropdownTitle(): string {
      if (this.selectedValues.length) {
        if (this.selectedValues.length === this.data.length) return "All venues";
        else if (this.selectedValues.length === 1) {
          const value = this.data.find(v => v.id === this.selectedValues[0]);
          return `${value.name}`;
        }
        else return `${this.$t('Selected venues')}: ${this.selectedValues.length}`;
      }

      return "Select venue";
    }
  },

  methods: {
    toggleAllPaymentMethods(checked: boolean): void {
      if (checked) this.selectedValues = this.data.map(v => v.id)
      else this.selectedValues = [];
    }
  },

  watch: {
    selectedValues: {
      handler(value: Array<number>) {
        this.allSelected = this.data.length === value.length;
        this.$emit("select", value);
      },
      immediate:true
    },
    data: {
      handler(value) {
        if (value) {
          this.selectedValues = this.selectedData;
          this.allSelected = this.data.length === this.selectedValues.length;
        }
      },
    }
  }
})
