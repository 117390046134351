
















































import Vue, {PropType} from "vue";
import { TopVotedSongsData, TopVotedArtistsData, TopAddSongsData, TopBoostsData } from '@/types';

export default Vue.extend({
  name: "lists-table",

  props: {
    topVotedSongs: Boolean as PropType<boolean>,
    topVotedArtists: Boolean as PropType<boolean>,
    topAddSongs: Boolean as PropType<boolean>,
    topSongBoost: Boolean as PropType<boolean>,
    tableTitle: String as PropType<string>,
    columns: Array as PropType<Array<string>>,
    items: Array as PropType<Array<TopVotedSongsData> | Array<TopVotedArtistsData> | Array<TopAddSongsData> | Array<TopBoostsData>>
  }
})
