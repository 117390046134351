

























































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import HeaderSearch from "@/components/dashboard/HeaderSearch.vue";
import CountsCard from "@/components/dashboard/CountsCard.vue";
import ListsTable from "@/components/dashboard/ListsTable.vue";
import StatsGraph from "@/components/dashboard/StatsGraph.vue";
import PageHeader from "@/components/page-header.vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "dashboard" as string,

  components: {
    Layout,
    PageHeader,
    "header-search": HeaderSearch,
    "counts-card": CountsCard,
    "lists-table": ListsTable,
    "stats-graph": StatsGraph,
  },

  data() {
    return {
      modalShow: false,
      title: "Dashboard",
    };
  },

  // async created(): Promise<void> {
  //   await this.setCompany();
  //
  //
  //
  //   if (!this.hasCompany) {
  //     if (this.getCompanyCount > 1) {
  //       // select company
  //       this.selectedCompany();
  //     } else if (this.getCompanyCount === 1) {
  //       // set company
  //       this.setCompany(this.getCompanies[0].id);
  //     }
  //   }
  // },

  async mounted(): Promise<void> {
    await this.setDashboard();
    let support = sessionStorage.getItem("Support");
    if (support != "true") {
      this.setPopularTopics();
      this.setPopularSections();
    }
  },

  computed: {
    ...mapGetters("companyModule", {
      hasCompany: "HAS_COMPANY",
      companyId: "GET_COMPANY_ID",
    }),
    ...mapGetters("userModule", {
      getUserData: "GET_USER_DATA",
    }),
    ...mapGetters("dashboardModule", {
      counts: "GET_COUNTS",
      stats: "GET_STATS",
      topVotedSongs: "GET_TOP_VOTED_SONGS",
      topVotedArtists: "GET_TOP_VOTED_ARTISTS",
      topAddSongs: "GET_TOP_ADD_SONGS",
      topBoosts: "GET_TOP_ADD_BOOSTS",
    }),
    /*getCompanyCount(): number {
        return this.getUserData.companies.length;
      },
      getCompanies(): Array<object> {
        return this.getUserData.companies;
      },*/
  },

  methods: {
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY",
    }),
    ...mapActions("dashboardModule", {
      setDashboard: "FETCH_DASHBOARD",
      fetchFilter: "FETCH_FILTER",
    }),
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES",
    }),
    ...mapActions("helpAndSupportModule", {
      setPopularTopics: "FETCH_POPULAR_TOPICS",
      setPopularSections: "FETCH_POPULAR_SECTIONS",
    }),
    selectedCompany() {
      this.$router.push("/select-company");
    },
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setVenues({ fetchRatings: false, name: "" });
          await this.fetchFilter();
          await this.setDashboard();
          let support = sessionStorage.getItem("Support");
          if (support != "true") {
            this.setPopularTopics();
            this.setPopularSections();
          }
        }
      },
    },
  },
});
