




import ECharts from "vue-echarts";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/scatter";
import "echarts/lib/chart/candlestick";
import "echarts/lib/chart/gauge";

import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import "echarts/lib/component/axis";

import Vue, { PropType } from "vue";
import login from "@/router/views/account/login.vue";
export default Vue.extend({
  name: "axiosChart",
  components: {
    ECharts,
  },
  data() {
    return {
      chartColors: [
        "#4F57A6",
        "#8D54A2",
        "#59C5C7",
        "#EBA721",
        "#F55F8A",
        "#83BCFF",
      ],
      showLabel:false,
      windowWidth: window.innerWidth,
      graphPosition: ['65%', '40%'],
      graphHeight: '100%',
      formattedData: this.graphData
    }
  },
  props: {
    graphData: {
      type: Array as PropType<Array<string>>,
      default: () => [],
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
        this.windowWidth = window.innerWidth
      }
    },
  beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

  computed: {
    doughnutChart(){
      return {
        legend: {
            orient: 'vertical',
            x: 'left',
            itemWidth:34,
            itemHeight:10,
            textStyle: {
                color: this.chartColors
            }
        },
        color: this.chartColors,
        series: [
            {
                name: 'Most Frequent Genres',
                type: 'pie',
                radius: ['50%', '70%'],
                center: this.graphPosition,
                avoidLabelOverlap: false,
                label: {
                    normal: {
                        show: this.showLabel,
                        position: 'center',
                        formatter: function(d) {
                           if(d.name.length >15){
                             return d.name.replace(" ", "\n")
                          }
                        },
                        textStyle: {
                            fontSize: '25',
                            fontWeight: 'bold'
                        }
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '25',
                            fontWeight: 'bold'
                        }
                    }
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: this.formattedData,
                height: this.graphHeight,
            }
        ]
}    }
  },

      watch: {
        graphData: {
        immediate: true,
        handler(value): void {
          if(value[0].name == this.$t("No data")){
            this.formattedData = value;
            this.chartColors = ["rgba(116,120,141,0.71)"];
            this.showLabel = true
          }else{
            this.chartColors =["#4F57A6", "#8D54A2", "#59C5C7", "#EBA721", "#F55F8A", "#83BCFF",];
            this.showLabel = false;
            this.formattedData = this.graphData.slice(0,10);
            if(this.graphData.length >= 10){
              let other = this.graphData.slice(10,this.graphData.length);
              let sum = other.reduce((a, b) =>  a + b.value, 0);
              let name = this.$t("Other");
              this.formattedData.push({value: sum,name: name});

            }

          }
        }
      },
        windowWidth: {
          immediate: true,
          handler(value){
            if(value > 1725){
              this.graphHeight = '100%';
              this.graphPosition = ['65%', '40%'];
            }
            if(value < 1550){
              this.graphHeight = '100%';
              this.graphPosition = ['75%', '40%']
            }
            if(value < 700){
              this.graphHeight = '80%';
              this.graphPosition = ['55%', '40%']
            }
            if(value < 550){
              this.graphHeight = '50%';
              this.graphPosition = ['45%', '70%']
            }
          }
        }
    }
});
