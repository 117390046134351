












import Vue, { PropType } from "vue";
import AxiosChart from "../charts/axios-chart.vue";
import {MostFrequentGenresData} from "@/types";

export default Vue.extend({
  name: "stats-graph",

  components: {
    AxiosChart,
  },

  props: {
    items: Array as PropType<Array<MostFrequentGenresData>>,
  },

  computed: {
    graphData() {
      if (!this.items || !this.items.length ) {
        return [{value:100,name: this.$t("No data")}]
      }
     return this.items.map(x => {
      return {value:x.percent,name:x.name}
    })
    }
  },
})
